module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3be0399b51054677db58a1f52da7e313"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","fr"],"defaultLanguage":"en","redirect":false,"i18nextOptions":{"prefixDefault":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/categories/:category","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/categories/:category/:page","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/emoji/:emoji","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/tags/:tag","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/tags/:tag/:page","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"ca616f640ed92d35a1fae4e2380a9594","enableOnDevMode":true,"pageViews":null,"trackPageViewsAs":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["euclid circular a"],"urls":["/fonts/euclid-circular-a.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
