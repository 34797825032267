exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-category-detail-js": () => import("./../../../src/templates/category-detail.js" /* webpackChunkName: "component---src-templates-category-detail-js" */),
  "component---src-templates-emoji-details-js": () => import("./../../../src/templates/emoji-details.js" /* webpackChunkName: "component---src-templates-emoji-details-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-tag-detail-js": () => import("./../../../src/templates/tag-detail.js" /* webpackChunkName: "component---src-templates-tag-detail-js" */)
}

